<template >
      <CCard class="border-0"  >
        <CCardHeader class="my-toolbar">
          <span style="color:red" class="mr-2">実装中</span>
          <div style="display: inline-block" class="mr-2">
<!--            <datepicker id="chartFrom" :value="chartFrom" :language="ja" v-model="chartFrom" format="yyyy-MM-dd" style="display:inline-block" typeable placeholder="日付を選択"/>から-->
<!--            <input id="chartFrom" type="date"  v-model="chartFrom" placeholder="日付を選択"/>まで-->

            <datepicker id="from" :value="chartFrom" :language="ja" style="width:100%"
                        v-model="chartFrom" format="yyyy-MM-dd" bootstrap-styling
                        typeable/>
          </div>
          <div class="card-header-actions mt-auto">
            <CButton size="sm" color="success mr-1" variant="outline" :pressed="chart1day" @click="chartDateChange(1)">日</CButton>
            <CButton size="sm" color="success mr-1" variant="outline" :pressed="chart7day" @click="chartDateChange(7)">週</CButton>
            <CButton size="sm" color="success mr-1" variant="outline" :pressed="chart30day" @click="chartDateChange(30)">30日</CButton>
            <CButton size="sm" color="success mr-1" variant="outline" :pressed="chart90day" @click="chartDateChange(90)">90日</CButton>

              <span class="mx-5">
                <CButton size="sm" color="success ml-2 mr-1" variant="outline" :pressed="summary" @click="chartTypeChange('summary')">サマリー</CButton>
                <CButton size="sm" color="success mr-1"      variant="outline" :pressed="ox" @click="chartTypeChange('ox')">酸素吸入</CButton>
                <CButton size="sm" color="success mr-1"      variant="outline" :pressed="spo2" @click="chartTypeChange('spo2')">SpO₂・心拍</CButton>
                <CButton size="sm" color="success mr-1"      variant="outline" :pressed="pl" @click="chartTypeChange('pl')">血圧</CButton>

                <CButton size="sm" color="info"  class="ml-1"    @click="chartTypeChange('pl')">CSV</CButton>
              </span>
            <CButton size="sm" color="primary mr-1 ml-5" :pressed="false" @click="chartPrint">レポート印刷</CButton>
          </div>
        </CCardHeader>
          <CCardBody class="datas py-0">
            <CRow class="print-area">
              <CCol >
                <div >患者名:朴 元浩  医療機関:東京電機病院  処方医：{{$store.state.user.userName}} 販売店：東京酸素株式会社 足立支店 印刷日:2020/12/30 グラフ表示期間:2020/10/31</div>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="4" >
                <CRow class="mt-2 no-print-area">
                  <CCol col="3">ID</CCol>
                  <CCol col="9">A-002-04-xxxx</CCol>
                </CRow>
                <CRow class="no-print-area">
                  <CCol col="3">氏名</CCol>
                  <CCol col="9">スズキ 〇〇</CCol>
                </CRow>
                <CRow class="no-print-area">
                  <CCol col="3">年齢</CCol>
                  <CCol col="9">51才</CCol>
                </CRow>
                <CRow class="no-print-area">
                  <CCol col="3">性別</CCol>
                  <CCol col="9">男性</CCol>
                </CRow>
                <CRow class="mt-3">
                  <table>
                    <tr>
                      <td rowspan="2">処方流量(L/分)</td>
                      <td>安静時</td>
                      <td>労作時</td>
                      <td>就寝時</td>
                      <td rowspan="2"><CButton size="sm" color="primary mr-1 ml-5" class="prescription" @click="prescription">処方</CButton></td>
                    </tr>
                    <tr>
                      <td>1.75</td>
                      <td>3.0</td>
                      <td>0.75</td>
                    </tr>
                  </table>
                </CRow>

                <CRow class="mt-3">
                  <CCol>
                    <table>
                      <tr class="data-label">
                        <td></td>
                        <td>最大</td>
                        <td>最小</td>
                        <td>平均</td>
                        <td>中央値</td>
                        <td>標準偏差</td>
                        <td>測定</td>
                      </tr>
                      <tr >
                        <td class="device" colspan="7">酸素濃縮装置</td>
                      </tr>
                      <tr>
                        <td >流量(&/分)</td>
                        <td>3.0</td>
                        <td>1.5</td>
                        <td>2.09</td>
                        <td>2.00</td>
                        <td>0.82</td>
                        <td>248.3h</td>
                      </tr>
                      <tr>
                        <td >呼吸数</td>
                        <td>18</td>
                        <td>12</td>
                        <td>15.2</td>
                        <td>14.2</td>
                        <td>2.35</td>
                        <td>248.3h</td>
                      </tr>
                      <tr>
                        <td colspan="7" style="color: #FFF">.</td>
                      </tr>
                      <tr>
                        <td class="device" colspan="7">パルスオキシメータ</td>
                      </tr>
                      <tr>
                        <td>SpO₂%</td>
                        <td>97</td>
                        <td>88</td>
                        <td>92.3</td>
                        <td>92.5</td>
                        <td>2.77</td>
                        <td>48.3m</td>
                      </tr>
                      <tr>
                        <td>bpm</td>
                        <td>130</td>
                        <td>70</td>
                        <td>100.9</td>
                        <td>105.5</td>
                        <td>17.7</td>
                        <td></td>
                      </tr>

                      <tr>
                        <td colspan="7" style="color: #FFF">.</td>
                      </tr>
                      <!--<tr>
                        <td class="device" colspan="7">血圧計</td>
                      </tr>
                      <tr>
                        <td>拡張期</td>
                        <td>162</td>
                        <td>125</td>
                        <td>143.7</td>
                        <td>144.0</td>
                        <td>11.4</td>
                        <td>22回</td>
                      </tr>
                      <tr>
                        <td>収縮期</td>
                        <td>110</td>
                        <td>85</td>
                        <td>96.7</td>
                        <td>96</td>
                        <td>7.27</td>
                        <td>22回</td>
                      </tr>
                      <tr>
                        <td>bpm</td>
                        <td>110</td>
                        <td>68</td>
                        <td>98.5</td>
                        <td>95</td>
                        <td>15.2</td>
                        <td>22回</td>
                      </tr>-->
                    </table>
                  </CCol>
                </CRow>
              </CCol>

              <CCol col="8" >
                <CRow class="mt-3">
                  <CCol class="px-0" :v-show="patientId != null">
                    <ox-charts ref="oxChart" :day-count="dayCount"  :series="series"></ox-charts>
                  </CCol>
                </CRow>
                <CRow >
                  <CCol class="px-0" :v-show="patientId != null">
<!--                    <apexchart class="a-charts" :width="chartWidth" :height="chartHeight" type="heatmap" :options="chartOptions"-->
<!--                               :series="series"></apexchart>-->
<!--                    <ox-charts  :day-count="dayCount"  :series="series"></ox-charts>-->
                  </CCol>
                </CRow>
                <CRow >
                  <CCol class="px-0" :v-show="patientId != null">
<!--                    <apexchart class="a-charts" :width="chartWidth" :height="chartHeight" type="heatmap" :options="chartOptions"-->
<!--                               :series="series"></apexchart>-->
<!--                    <ox-charts  :day-count="dayCount"  :series="series"></ox-charts>-->

                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
      </CCard>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import {ja} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import OxCharts from "@/component/parts/charts/OxCharts";
export default {
  name: 'PatientSummary',
  components: { datepicker,OxCharts},
  data: function () {
    return {
      patientId:this.$route.params.id,
      dayCount:1,
      summary:true,
      ox:false,
      spo2:false,
      pl:false,
      chart1day:true,
      chart7day:false,
      chart30day:false,
      chart90day:false,
      chartFrom:null,
      chartTo:null,
      series:[],
      chartOptions: {
        yaxis: {
          show: true,
          //showAlways: true,
          showForNullSeries: true,
          seriesName: undefined,
          opposite: false,
          reversed: false,
          logarithmic: false,
          tickAmount: 6,
          min: 6,
          max: 6,
          forceNiceScale: false,
          floating: false,
          decimalsInFloat: undefined,
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            // formatter: (value) => {
            //   if (value % 10 == 0) {
            //     return value;
            //   } else {
            //     return null;
            //   }
            // },
          },
          axisBorder: {
            show: true,
            color: '#78909C',
            offsetX: 0,
            offsetY: 0
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: '#78909C',
            width: 6,
            offsetX: 0,
            offsetY: 0
          },
          title: {
            text: undefined,
            rotate: -90,
            offsetX: 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
          crosshairs: {
            show: true,
            position: 'back',
            stroke: {
              color: '#b6b6b6',
              width: 1,
              dashArray: 0,
            },
          },
          tooltip: {
            enabled: false,
            offsetX: 0,
          },
        },
        chart: {
          height: 350,
          type: 'heatmap',
          animations: {enabled: false,},
          toolbar: {show: false,}
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'right',
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: "black",
            radius: 8,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0

          },
        },
        plotOptions: {
          heatmap: {
            shadeIntensity: 0.5,
            radius: 0,
            useFillColorAsStroke: false,
            colorScale: {
              ranges: [{
                from: 0,
                to: 0,
                name: '1',
                color: '#00A100',
                foreColor: "#FFFF00"
              },
                {
                  from: 1,
                  to: 1,
                  name: '2',
                  color: '#128FD9'
                },
                {
                  from: 2,
                  to: 2,
                  name: '3',
                  color: '#FFB200'
                },
                {
                  from: 3,
                  to: 3,
                  name: '4',
                  color: '#FF0000'
                }
              ]
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 1
        },
        title: {
          text: '酸素流入',
          align: 'center',
          floating: true,
          style: {
            fontSize: '15px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238'
          },
        },
      }
    }
  },
  created: function() {
    window.addEventListener('beforeprint',this.beforePrint);
    window.addEventListener('afterprint',this.afterPrint);
    //window.onbeforeprint = this.beforePrint;
    //window.onafterprint = this.afterPrint;
  },
  destroyed: function() {
    window.removeEventListener('beforeprint',this.beforePrint);
    window.removeEventListener('afterprint',this.afterPrint);
  },
  methods: {
    getData(){
      // this.series = [];
      if(this.patientId == null){
        return;
      }
      let graphButtonType = "";
      if (this.summary) {
        graphButtonType = "summary";
      } else if (this.ox) {
        graphButtonType = "ox";
      } else if (this.spo2) {
        graphButtonType = "spo2";
      } else if (this.pl) {
        graphButtonType = "pl";
      }


      this.axios.get(`/api/measureValue/getAllChart/${this.patientId}/${graphButtonType}/${this.dayCount}/${this.chartFrom}`)
          .then(response => {
            console.log(new Date(),"/api/measureValue/getOxGraphData/  end");
            this.series = response.data.result;
            // console.log(JSON.stringify(this.series));
            console.log(new Date(),"/api/measureValue/getOxGraphData/  end2");
          }).catch(error => {
      })
          .finally(() => {
          });

      // fetch(`/api/measureValue/getOxGraphData/${this.patientId}/${this.dayCount}/${this.fromDate}`,{
      //   headers : {
      //     'Content-Type': 'application/json',
      //         'Accept': 'application/json'
      //   }
      // })
      // .then(response => response.json())
      // .then(json => this.series = json.result)
    },
    prescription(){
      alert("未実装");
    },
    chartPrint(){
      window.print();
    },
    chartTypeChange:function(type){
      this.summary = type == 'summary';
      this.ox = type == 'ox';
      this.spo2 = type == 'spo2';
      this.pl = type == 'pl';
    },chartDateChange:function(day){
      this.chart1day = day == 1;
      this.chart7day = day == 7;
      this.chart30day = day == 30;
      this.chart90day = day == 90;
      this.dayCount = day;
    },
    beforePrint(){
      console.log("beforePrint==");
      // this.chartWidth=540;this.chartHeight=175;
      // this.chartWidth=600;this.chartHeight=175;
    },
    afterPrint(){
      console.log("afterPrint-");
      // this.chartWidth=740;this.chartHeight=225;
    },
    generateData(count, yrange) {
      var i = 0; var series = [];
      while (i < count) {
        series.push({ x: i.toString(),
          y: Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min });
        i++;
      } return series;
    },
  },
  watch: {
    chartFrom: function () {
      if (this.chartFrom) {
        this.chartFrom = moment(this.chartFrom).format('YYYY-MM-DD');
        this.getData();
      }
    },
    dayCount() {
      this.getData();
    },
  },
  computed:{  ja() { return ja;  }  },
  mounted(){
    this.patientId = this.$route.params.id;
    // this.chartFrom = moment().format("YYYY-MM-DD");
    this.chartFrom = moment('2021-02-19').format("YYYY-MM-DD");
    // this.getData();
  }
}
</script>

<style>
.print-area{
  display:none;
}
.datas{
  color:black;
  font-size: 18px;
  border: none;
}
.data-label{
  color:black;
  font-size: 13px;
  text-align: center;
}
.device{
  color:black;
  font-size: 13px;
}
.c-main{
  /*height:100%;*/
  background-color: white;
}

@media print {
  .TheHeader,.my-toolbar,.prescription,.no-print-area{
    display: none;
  }
  .print-area{
    display:block;
    font-size: 14px;
  }
  .a-charts:not(:first-of-type){
    margin-top: -10px;
  }
  .datas{
    border: none;
    font-size: 16px;
  }
  @page {
    margin: 10px;
    size: A4 landscape;
  }
}
</style>